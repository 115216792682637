.about {
  background: #FFF;
  color: #242424;
  padding: 8.8em 0;

  h1 {
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: center;
    max-width: 600px;
    margin: 3rem auto 0;
  }
}

.about-image {
  text-align: center;

  img {
    width: 220px;
    border-radius: 50%;
    margin: 0 auto;
  }
}

// @media screen and (min-width: 540px) {
//   .about {

//     .about-section {
//       display: flex;
//       align-items: center;
//       max-width: 1200px;
//       margin: 0 auto;

//       .about-description {
//         text-align: left;
//         padding-left: 2em;
//       }

//       .about-image {
//         img {
//           width: 220px;
//         }
//       }
//     }
//   }
// }