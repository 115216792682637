.works-section {
  background: #000;
}

.work__title {
  text-align: center;
  font-size: 3.2rem;
  margin: 0;
  padding: 2rem 0;
}

.work__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover !important;
  background-position: 50% 50%;
  transition: all .2s ease-in-out;
}

.work-tile {
  color: #fff;
  text-align: left;
  background-size: cover;
  background-position: 0% 50%;
  height: 50vw;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  h3,
  p {
    position: absolute;
    left: 3%;
    z-index: 300;
  }

  h3 {
    bottom: 3.5rem;
  }

  p {
    bottom: 1rem;
    font-size: 2rem;
    font-weight: 700;
  }
}

.gradient-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.works-section div:nth-child(4) {

  h3,
  p {
    color: #000;
  }

  .gradient-title {
    opacity: 0.2;
  }
}

.works-section div:nth-child(2) {
  .work-logo {
    margin-top: 20px;
    width: 120px;
  }
}

.works-section div:nth-child(5) {
  .work-logo {
    width: 310px;
    left: 20px;
  }
}

.work-logo {
  width: 180px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0 auto 10px;
}

@media screen and (min-width: 640px) {
  .work-tile {
    width: 50%;
    height: 50vw;
    display: inline-flex;

  }

  .works-section div:nth-child(1),
  .works-section div:nth-child(2),
  .works-section div:nth-child(3),
  .works-section div:nth-child(5) {
    &:hover .work__bg {
      opacity: 0.6;
      transform: scale(1.1);
    }
  }

  .works-section div:nth-child(4) {
    &:hover .work__bg {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }

  .works-section div:nth-child(5) {
    .work__bg {
      background: url("../../../assets/images/home/works/work-tiles/edm-mobile.jpg")
    }
  }


  .works-section div:last-child {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .work-tile {
    width: 33.33%;
    height: 22vw;
    min-height: 300px;
    max-height: 500px;
    background-size: 100%;
    background-position: center;
  }

  .works-section div:last-child {
    width: 66.67%;
  }
}