.hero {
  background: #e5e5e5;
  background-size: cover;
  color: #fff;
  padding-top: 65px;
  position: relative;
  background-position: 60%;

  .container {
    padding: 4.8rem 0;
    position: relative;
    z-index: 2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .4;
    z-index: 0;
  }
}