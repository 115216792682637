.button {
  background: #00c454;
  color: #fff;
  border-radius: 5px;
  padding: 12px 40px;
  border: none;
  font-size: 1.6rem;
  margin: 1.6rem 0;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 3px solid #30901f;

  &:hover {
    background: darken(#00c454, 9%);
    border-bottom: 3px solid transparent;
    transform: translateY(3%);
  }
}

.button.blue {
  background: #F4C022;
  font-weight: bold;
  border-bottom: 3px solid #AE8E2C;

  &:hover {
    background: darken(#F4C022, 9%);
    border-bottom: 3px solid transparent;
    transform: translateY(3%);
  }
}