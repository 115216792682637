@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
html,
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  box-sizing: border-box; }

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px; }

.section {
  padding: 4.8rem 0; }

h1 {
  font-size: 4em; }

p {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0.2px; }

a {
  color: #5959f5; }

.nav {
  color: #fff;
  padding: 20px;
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 1.6rem;
  font-weight: 700; }

.nav__container {
  display: flex;
  justify-content: space-between; }

.nav__logo {
  display: inline-block;
  color: #fff; }

.nav__links {
  padding: 0;
  margin: 0;
  display: inline-block; }

.nav__link {
  display: inline-block;
  margin-left: 2rem; }

.nav__link a {
  color: #fff;
  text-decoration: none; }

.nav {
  color: #fff;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 1.6rem;
  font-weight: 700;
  z-index: 10; }

.nav__container {
  display: flex;
  justify-content: space-between; }

.nav__logo {
  display: inline-block; }

.nav__links {
  padding: 0;
  margin: 0;
  display: inline-block; }

.nav__link {
  display: inline-block;
  margin-left: 2rem; }

.nav__link a {
  color: #fff;
  text-decoration: none; }

.button {
  background: #00c454;
  color: #fff;
  border-radius: 5px;
  padding: 12px 40px;
  border: none;
  font-size: 1.6rem;
  margin: 1.6rem 0;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 3px solid #30901f; }
  .button:hover {
    background: #009640;
    border-bottom: 3px solid transparent;
    -webkit-transform: translateY(3%);
            transform: translateY(3%); }

.button.blue {
  background: #F4C022;
  font-weight: bold;
  border-bottom: 3px solid #AE8E2C; }
  .button.blue:hover {
    background: #dda90b;
    border-bottom: 3px solid transparent;
    -webkit-transform: translateY(3%);
            transform: translateY(3%); }

.footer {
  background: #8BB2B7;
  color: #000;
  font-size: 1.2rem;
  text-align: center;
  padding: 2rem 0; }
  .footer p {
    margin: 0;
    cursor: pointer;
    line-height: 1.6; }
  .footer a {
    font-size: 1.5rem;
    line-height: 1.6; }
    .footer a img {
      width: 45px;
      padding: 0;
      margin: 0 5px; }
  .footer .footer__social {
    display: flex;
    justify-content: center; }
  .footer .footer__top {
    padding-top: 2rem; }
    .footer .footer__top img {
      width: 45px;
      padding: 0;
      margin: 0 5px; }
  .footer .divider {
    display: none; }

@media screen and (min-width: 810px) {
  .footer {
    padding: 1rem 0; }
    .footer .footer__top {
      padding-top: 0; }
    .footer .footer__social {
      margin-left: -13%; }
  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .divider {
    display: inline-block; }
  br {
    display: none; } }

.facebook {
  background: #3366CC;
  border-bottom: 3px solid #1F50A0; }
  .facebook:hover {
    background: #2a54a7; }

.linkedin {
  background: #3a98cc;
  border-bottom: 3px solid #2584ad; }
  .linkedin:hover {
    background: #2c7eac; }

.email {
  background: #56bed6;
  border-bottom: 3px solid #51a8bc; }
  .email:hover {
    background: #32afcc; }

.top {
  background: #609093;
  border-bottom: 3px solid #4d7a7c;
  border-radius: 50%; }
  .top:hover {
    background: #4e7577; }

.hero-section {
  background: #8ab2b7;
  height: 90vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .hero-section .hero-unit {
    display: flex;
    width: 100%;
    max-width: 700px;
    align-items: center;
    margin-bottom: -58px; }
  .hero-section .main-container {
    margin: 5% auto 0;
    width: 100%;
    text-align: center; }
  .hero-section .pen {
    position: absolute;
    right: 0;
    display: none; }
  .hero-section .small-logo {
    position: relative;
    z-index: 300; }
    .hero-section .small-logo #logo-02 {
      display: none; }
  .hero-section .name-description {
    position: relative;
    z-index: 300; }
    .hero-section .name-description #logo-03 {
      width: 100%; }
  .hero-section #logo-01,
  .hero-section #logo-02,
  .hero-section #logo-03,
  .hero-section #logo-04,
  .hero-section #logo-05 {
    display: block;
    margin: 0 auto; }

@media screen and (min-width: 680px) {
  .main-container {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  .hero-section .pen {
    display: block;
    top: -70px;
    left: 300px; }
  .hero-section .main-container {
    width: 80%; }
  .hero-section .small-logo {
    display: inline-flex;
    width: 100px;
    margin: 0; }
    .hero-section .small-logo #logo-01 {
      margin: 0; }
    .hero-section .small-logo #logo-02 {
      margin: 0;
      display: block; }
  .hero-section .name-description {
    display: inline-block; }
    .hero-section .name-description #logo-03 {
      position: relative;
      top: 10px; }
    .hero-section .name-description #logo-04 {
      margin: 0; } }

.works-section {
  background: #000; }

.work__title {
  text-align: center;
  font-size: 3.2rem;
  margin: 0;
  padding: 2rem 0; }

.work__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: cover !important;
  background-position: 50% 50%;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.work-tile {
  color: #fff;
  text-align: left;
  background-size: cover;
  background-position: 0% 50%;
  height: 50vw;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  overflow: hidden; }
  .work-tile a {
    display: inline-block;
    width: 100%;
    height: 100%;
    color: #fff; }
  .work-tile h3,
  .work-tile p {
    position: absolute;
    left: 3%;
    z-index: 300; }
  .work-tile h3 {
    bottom: 3.5rem; }
  .work-tile p {
    bottom: 1rem;
    font-size: 2rem;
    font-weight: 700; }

.gradient-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.65)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%); }

.works-section div:nth-child(4) h3,
.works-section div:nth-child(4) p {
  color: #000; }

.works-section div:nth-child(4) .gradient-title {
  opacity: 0.2; }

.works-section div:nth-child(2) .work-logo {
  margin-top: 20px;
  width: 120px; }

.works-section div:nth-child(5) .work-logo {
  width: 310px;
  left: 20px; }

.work-logo {
  width: 180px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0 auto 10px; }

@media screen and (min-width: 640px) {
  .work-tile {
    width: 50%;
    height: 50vw;
    display: inline-flex; }
  .works-section div:nth-child(1):hover .work__bg,
  .works-section div:nth-child(2):hover .work__bg,
  .works-section div:nth-child(3):hover .work__bg,
  .works-section div:nth-child(5):hover .work__bg {
    opacity: 0.6;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .works-section div:nth-child(4):hover .work__bg {
    opacity: 0.8;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .works-section div:nth-child(5) .work__bg {
    background: url(/static/media/edm-mobile.1dd25151.jpg); }
  .works-section div:last-child {
    width: 100%; } }

@media screen and (min-width: 900px) {
  .work-tile {
    width: 33.33%;
    height: 22vw;
    min-height: 300px;
    max-height: 500px;
    background-size: 100%;
    background-position: center; }
  .works-section div:last-child {
    width: 66.67%; } }

.about {
  background: #FFF;
  color: #242424;
  padding: 8.8em 0; }
  .about h1 {
    margin-top: 0; }
  .about p {
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: center;
    max-width: 600px;
    margin: 3rem auto 0; }

.about-image {
  text-align: center; }
  .about-image img {
    width: 220px;
    border-radius: 50%;
    margin: 0 auto; }

h2 {
  font-size: 1.9rem;
  margin: 0; }

.summary {
  background: #D3D3D3; }
  .summary p {
    margin: 6px 0 0; }

.base-image {
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  text-align: center; }
  .base-image img {
    width: 100%; }

.preview {
  text-align: center; }

.list .list-title {
  font-weight: bold; }

.list ul {
  padding-left: 16px;
  font-size: 1.5rem; }
  .list ul li {
    line-height: 29px;
    margin-bottom: 10px; }

.app-flow .base-image {
  margin: 4em auto; }

.app-design {
  padding: 0 0 4.8rem;
  background: #d9d8d9; }

.base-video {
  text-align: center; }
  .base-video img,
  .base-video video {
    width: 70%;
    margin: 0; }
  .base-video img + p {
    font-weight: bold;
    margin: 0 0 10px; }
  .base-video a {
    color: #2F4DD1; }
  .base-video .ebp-wireframe {
    padding-top: 2rem; }

@media screen and (min-width: 780px) {
  .base-image .ebp-mobile {
    margin: 4em 0;
    width: 100%; }
  .base-image .ebp-wireframe {
    margin: 0;
    width: 90%;
    padding-top: 4rem; }
  .base-image .ebp-webdesign {
    width: 80%;
    padding: 4rem 0; } }

.half {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 3rem 0 0;
  position: relative;
  z-index: 300; }
  .half div {
    width: 100%; }

@media screen and (min-width: 980px) {
  .half {
    flex-direction: row;
    align-items: center; } }

.banner-section {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap; }
  .banner-section iframe {
    border: 0;
    text-align: center;
    display: block;
    margin: 1rem auto;
    width: 300px;
    height: 250px; }

@media screen and (min-width: 1220px) {
  .banner-section {
    flex-direction: row;
    justify-content: space-between; }
    .banner-section iframe {
      margin: 0 auto; } }

.hero {
  background: #e5e5e5;
  background-size: cover;
  color: #fff;
  padding-top: 65px;
  position: relative;
  background-position: 60%; }
  .hero .container {
    padding: 4.8rem 0;
    position: relative;
    z-index: 2; }
  .hero .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .4;
    z-index: 0; }


.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.vid.section {
  padding: 2rem 0; }
  .vid.section h2 {
    margin-bottom: 20px; }

.creative-direction,
.graphic-design,
.animation-video {
  background: #141414;
  color: #fff; }

.svg-animations {
  margin: 4em auto;
  width: 100%;
  justify-content: center;
  display: flex; }

.graphic-design {
  padding: 4.8rem 0 8rem; }

@media screen and (min-width: 980px) {
  .svg-animations {
    margin: 2em auto; }
    .svg-animations svg {
      width: 70%; }
  .base-image .red-letter-a {
    position: absolute;
    left: 360px;
    top: -80px;
    margin: 0;
    z-index: -1;
    width: 80%; } }

#showreel-section {
  height: 600px;
  margin: 0;
  overflow: hidden;
  position: relative; }
  #showreel-section .showreel-play-btn {
    position: absolute;
    z-index: 420;
    width: 100px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  #showreel-section .bg-image-sections {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  #showreel-section .boxer-man {
    background-image: url(/static/media/01-boxer-man-bg.0ad0f78d.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
    #showreel-section .boxer-man .character {
      position: absolute;
      width: 100%;
      z-index: 40;
      bottom: 0;
      margin: auto;
      left: -50px;
      width: 540px; }
    @media (min-width: 560px) {
      #showreel-section .boxer-man .boxer-man .character {
        left: -30px;
        width: 650px; } }
  #showreel-section .ocean-bg {
    background-image: url(/static/media/02-swimmer-bg.ceedf9c4.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .swimmer-girl {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .swimmer-inner {
    z-index: 10 !important; }
  #showreel-section .swimmer-girl-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: -80px;
    bottom: 85px; }
    #showreel-section .swimmer-girl-container .swimmer-girl-01 {
      position: absolute;
      width: 442px;
      bottom: -63px;
      left: 104px;
      z-index: -10; }
    #showreel-section .swimmer-girl-container .swimmer-girl-02 {
      position: absolute;
      width: 399px;
      left: 252px;
      top: 143px; }
  @media (min-width: 560px) {
    #showreel-section .swimmer-girl-container {
      left: 140px;
      bottom: 90px; } }
  #showreel-section .food-bg {
    background-image: url(/static/media/03-food-dudes-bg.5eff9397.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .food-dudes {
    position: absolute;
    width: 550px;
    bottom: 40px;
    left: -70px;
    z-index: 50; }
  @media (min-width: 560px) {
    #showreel-section .food-dudes {
      width: 620px;
      bottom: 40px;
      left: 20px; } }
  #showreel-section .fashion-bg {
    background-image: url(/static/media/04-fashion-bg.88c31291.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .fashion-chick {
    position: absolute;
    width: 340px;
    bottom: 40px;
    left: 20px;
    z-index: 50; }
  @media (min-width: 560px) {
    #showreel-section .fashion-chick {
      left: 180px; } }
  #showreel-section .swimmer-girl {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .phone-inner {
    z-index: 10 !important; }
  #showreel-section .phone-dude-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: -100px;
    bottom: 90px; }
    #showreel-section .phone-dude-container .phone-dude-01 {
      position: absolute;
      width: 452px;
      bottom: -43px;
      left: 126px;
      z-index: -10; }
    #showreel-section .phone-dude-container .phone-dude-02 {
      position: absolute;
      width: 410px;
      left: 260px;
      top: 42px; }
  @media (min-width: 560px) {
    #showreel-section .phone-dude-container {
      left: 0; } }
  #showreel-section .phone-bg {
    background-image: url(/static/media/05-phone-dude-bg.3d780365.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .hype-shoes-inner {
    z-index: 10 !important; }
  #showreel-section .hype-shoes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: 0;
    bottom: 0; }
    #showreel-section .hype-shoes-container .hype-shoes-01 {
      position: absolute;
      width: 532px;
      bottom: 54px;
      left: -14px;
      z-index: -10; }
    #showreel-section .hype-shoes-container .hype-shoes-02 {
      position: absolute;
      width: 164px;
      left: -85px;
      top: 144px; }
  #showreel-section .hype-shoes-bg {
    background-image: url(/static/media/06-hype-shoes-bg.77a9a148.jpg);
    background-size: cover;
    background-position: 'center bottom';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  #showreel-section .container-showreel-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    max-width: 700px;
    max-height: 400px;
    z-index: 400;
    border: 2px solid #fff;
    pointer-events: all;
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    overflow: hidden;
    cursor: pointer;
    pointer-events: all !important; }
    #showreel-section .container-showreel-thumbnail .background-container {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      background: #151515; }
    #showreel-section .container-showreel-thumbnail .boxer-character {
      position: absolute;
      bottom: 0;
      left: -150px;
      width: 650px; }
    @media (min-width: 560px) {
      #showreel-section .container-showreel-thumbnail .boxer-character {
        left: -50px;
        width: 660px; } }
  #showreel-section .showreel-btn {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    border-top: 2px solid #FFF;
    line-height: 55px;
    font-size: 26px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    color: #fff;
    background: #151515;
    z-index: 400;
    -webkit-transition: background .2s ease-in;
    transition: background .2s ease-in; }
  #showreel-section p {
    text-align: center; }
  #showreel-section button {
    text-align: center;
    color: #fff;
    border-radius: 0px;
    width: 100%;
    letter-spacing: 0; }

.container-showreel-thumbnail:hover .showreel-btn {
  background: #cf1427 !important; }

@media (min-width: 820px) {
  .container-showreel-thumbnail {
    overflow: visible !important; }
  .showreel-btn {
    z-index: 200; } }

.showreel-btn:hover {
  background: pink; }

#showreel-player-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.98);
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer; }
  #showreel-player-video iframe {
    width: 100%;
    height: 50%; }

#showreel-player-video {
  position: fixed; }

#showreel-player-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 40%; }

@media screen and (min-width: 1024px) {
  #showreel-player-video iframe {
    width: 960px;
    height: 540px; } }

#loader-logo-outline {
  width: 100%; }

.carousel .control-dots {
  position: relative; }

.carousel .slide {
  background: transparent; }

.carousel .control-dots .dot {
  background: #333; }

.mrec {
  margin: 0 10px; }

.not-found {
  background: dadada;
  height: calc(100vh - 7rem);
  display: flex;
  align-items: center;
  text-align: center; }
  .not-found a {
    color: #000; }

