.creative-direction,
.graphic-design,
.animation-video {
  background: #141414;
  color: #fff;
}

.svg-animations {
  margin: 4em auto;
  width: 100%;
  justify-content: center;
  display: flex;

}

.graphic-design {
  padding: 4.8rem 0 8rem;
}

@media screen and (min-width: 980px) {
  .svg-animations {
    margin: 2em auto;

    svg {
      width: 70%;
    }
  }

  .base-image {
    .red-letter-a {
      position: absolute;
      left: 360px;
      top: -80px;
      margin: 0;
      z-index: -1;
      width: 80%;
    }
  }
}

// ================
// Showreel
// ================

// =========
// Variables
// =========
$mobile: 560px;
$tablet: 820px;
$desktop: 1024px;
// ================
// Showreel section
// ================

#showreel-section {
  // background: #151515;
  height: 600px;
  margin: 0;
  overflow: hidden;
  position: relative;

  .showreel-play-btn {
    position: absolute;
    z-index: 420;
    width: 100px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .bg-image-sections {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #151515;
  }

  .boxer-man {
    background-image: url('../../../assets/images/works/redscope/showreel/01-boxer-man-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .character {
      position: absolute;
      width: 100%;
      z-index: 40;
      bottom: 0;
      margin: auto;
      left: -50px;
      width: 540px;
    }

    @media(min-width: $mobile) {
      .boxer-man .character {
        left: -30px;
        width: 650px;
      }
    }
  }

  .ocean-bg {
    background-image: url('../../../assets/images/works/redscope/showreel/02-swimmer-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .swimmer-girl {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .swimmer-inner {
    z-index: 10 !important;
  }

  .swimmer-girl-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: -80px;
    bottom: 85px;

    .swimmer-girl-01 {
      position: absolute;
      width: 442px;
      bottom: -63px;
      left: 104px;
      z-index: -10;
    }

    .swimmer-girl-02 {
      position: absolute;
      width: 399px;
      left: 252px;
      top: 143px;
    }
  }

  @media (min-width: $mobile) {
    .swimmer-girl-container {
      left: 140px;
      bottom: 90px;
    }
  }

  .food-bg {
    background-image: url('../../../assets/images/works/redscope/showreel/03-food-dudes-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .food-dudes {
    position: absolute;
    width: 550px;
    bottom: 40px;
    left: -70px;
    z-index: 50;
  }

  @media (min-width: $mobile) {
    .food-dudes {
      width: 620px;
      bottom: 40px;
      left: 20px;
    }
  }

  .fashion-bg {
    background-image: url('../../../assets/images/works/redscope/showreel/04-fashion-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .fashion-chick {
    position: absolute;
    width: 340px;
    bottom: 40px;
    left: 20px;
    z-index: 50;
  }

  @media (min-width: $mobile) {
    .fashion-chick {
      left: 180px;
    }
  }

  .swimmer-girl {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .phone-inner {
    z-index: 10 !important;
  }

  .phone-dude-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: -100px;
    bottom: 90px;

    .phone-dude-01 {
      position: absolute;
      width: 452px;
      bottom: -43px;
      left: 126px;
      z-index: -10;
    }

    .phone-dude-02 {
      position: absolute;
      width: 410px;
      left: 260px;
      top: 42px;
    }
  }

  @media (min-width: $mobile) {
    .phone-dude-container {
      left: 0;
    }
  }

  .phone-bg {
    background-image: url('../../../assets/images/works/redscope/showreel/05-phone-dude-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .hype-shoes-inner {
    z-index: 10 !important;
  }

  .hype-shoes-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 300;
    left: 0;
    bottom: 0;

    .hype-shoes-01 {
      position: absolute;
      width: 532px;
      bottom: 54px;
      left: -14px;
      z-index: -10;
    }

    .hype-shoes-02 {
      position: absolute;
      width: 164px;
      left: -85px;
      top: 144px;
    }
  }

  .hype-shoes-bg {
    background-image: url('../../../assets/images/works/redscope/showreel/06-hype-shoes-bg.jpg');
    background-size: cover;
    background-position: 'center bottom';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .container-showreel-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    max-width: 700px;
    max-height: 400px;
    z-index: 400;
    border: 2px solid #fff;
    pointer-events: all;
    transform: scale(.8);
    overflow: hidden;
    cursor: pointer;
    pointer-events: all !important;

    .background-container {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      background: #151515;
    }

    .boxer-character {
      position: absolute;
      bottom: 0;
      left: -150px;
      width: 650px;
    }

    @media (min-width: $mobile) {
      .boxer-character {
        left: -50px;
        width: 660px;
      }
    }
  }

  .showreel-btn {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    border-top: 2px solid #FFF;
    line-height: 55px;
    font-size: 26px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 400;
    color: #fff;
    background: #151515;
    z-index: 400;
    transition: background .2s ease-in;
  }

  p {
    text-align: center;
  }

  button {
    text-align: center;
    color: #fff;
    border-radius: 0px;
    width: 100%;
    letter-spacing: 0;
  }
}

.container-showreel-thumbnail:hover .showreel-btn {
  background: #cf1427 !important;
}

@media (min-width: $tablet) {
  .container-showreel-thumbnail {
    overflow: visible !important;
  }

  .showreel-btn {
    z-index: 200;
  }
}

.showreel-btn:hover {
  background: pink;
}

#showreel-player-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.98);
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: pointer;

  iframe {
    width: 100%;
    height: 50%;
  }
}

#showreel-player-video {
  position: fixed;
}

#showreel-player-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 90%;
  height: 40%;
}

@media screen and (min-width: $desktop) {
  #showreel-player-video iframe {
    width: 960px;
    height: 540px;
  }
}

#loader-logo-outline {
  width: 100%;
}