.nav {
  color: #fff;
  padding: 20px;
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, .3);
  font-size: 1.6rem;
  font-weight: 700;
}

.nav__container {
  display: flex;
  justify-content: space-between;
}

.nav__logo {
  display: inline-block;
  color: #fff;
}

.nav__links {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.nav__link {
  display: inline-block;
  margin-left: 2rem;
}

.nav__link a {
  color: #fff;
  text-decoration: none;
}