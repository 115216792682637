.hero-section {
  // background: #323132;
  background: #8ab2b7;
  height: 90vh;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .hero-unit {
    display: flex;
    width: 100%;
    max-width: 700px;
    align-items: center;
    // max-height: 600px;
    // min-height: 600px;
    margin-bottom: -58px;
  }

  .main-container {
    margin: 5% auto 0;
    width: 100%;
    text-align: center;
  }

  .pen {
    position: absolute;
    right: 0;
    display: none;
  }

  .small-logo {
    position: relative;
    z-index: 300;

    #logo-02 {
      display: none;
    }
  }

  .name-description {
    position: relative;
    z-index: 300;

    #logo-03 {
      width: 100%;
    }
  }

  #logo-01,
  #logo-02,
  #logo-03,
  #logo-04,
  #logo-05 {
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 680px) {
  .main-container {
    transform: scale(1.3);
  }


  .hero-section {
    // .hero-unit {
    //   width: 40%;
    // }

    .pen {
      display: block;
      top: -70px;
      left: 300px;
    }

    .main-container {
      width: 80%;
    }

    .small-logo {
      display: inline-flex;
      width: 100px;
      margin: 0;

      #logo-01 {
        margin: 0;
      }

      #logo-02 {
        margin: 0;
        display: block;
      }
    }

    .name-description {
      display: inline-block;

      #logo-03 {
        position: relative;
        top: 10px;
      }

      #logo-04 {
        margin: 0;
      }
    }
  }
}