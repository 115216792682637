.footer {
  background: #8BB2B7;
  color: #000;
  font-size: 1.2rem;
  text-align: center;
  padding: 2rem 0;

  p {
    margin: 0;
    cursor: pointer;
    // font-size: 1.5rem;
    line-height: 1.6;
  }

  a {

    font-size: 1.5rem;
    line-height: 1.6;
    // margin: 0 1.2rem;

    img {
      width: 45px;
      padding: 0;
      margin: 0 5px;
    }
  }

  .footer__social {
    display: flex;
    justify-content: center;
  }

  .footer__top {
    padding-top: 2rem;

    img {
      width: 45px;
      padding: 0;
      margin: 0 5px;
    }
  }

  .divider {
    display: none;
  }

}

@media screen and (min-width: 810px) {
  .footer {
    padding: 1rem 0;

    .footer__top {
      padding-top: 0;
    }

    .footer__social {
      margin-left: -13%;
    }
  }

  .footer-content {
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .divider {
    display: inline-block;
  }

  br {
    display: none;
  }
}

// SOCIAL 
.facebook {
  background: #3366CC;
  border-bottom: 3px solid #1F50A0;

  &:hover {
    background: darken(#3366CC, 9%);
  }
}

.linkedin {
  background: #3a98cc;
  border-bottom: 3px solid #2584ad;

  &:hover {
    background: darken(#3a98cc, 9%);
  }
}

.email {
  background: #56bed6;
  border-bottom: 3px solid #51a8bc;

  &:hover {
    background: darken(#56bed6, 9%);
  }
}

.top {
  background: #609093;
  border-bottom: 3px solid #4d7a7c;
  border-radius: 50%;

  &:hover {
    background: darken(#609093, 9%);
  }
}