.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.vid.section {
  padding: 2rem 0;

  h2 {
    margin-bottom: 20px;
  }
}