h2 {
  font-size: 1.9rem;
  margin: 0;
}

.summary {
  background: #D3D3D3;


  p {
    margin: 6px 0 0;
  }
}

// ================
// Wireframe
// ================

.wireframe {
  // padding: 4.8rem 0;
}

.base-image {
  width: 90%;
  margin: 0 auto;
  padding-top: 4rem;
  text-align: center;

  img {
    width: 100%;
  }
}

.preview {
  text-align: center;
}

.list {
  .list-title {
    font-weight: bold;

  }

  ul {
    padding-left: 16px;
    font-size: 1.5rem;

    li {
      line-height: 29px;
      margin-bottom: 10px;
    }
  }
}

// ================
// App flow
// ================

.app-flow {
  // padding: 4.8rem 0;

  .base-image {
    margin: 4em auto;
  }
}

// ================
// Web design
// ================

.web-design {
  // padding: 4.8rem 0;
}

// ================
// App design
// ================

.app-design {
  padding: 0 0 4.8rem;
  background: #d9d8d9;
}

.base-video {
  text-align: center;

  img,
  video {
    width: 70%;
    margin: 0;
  }

  img+p {
    font-weight: bold;
    margin: 0 0 10px;
  }

  a {
    color: #2F4DD1;
  }

  .ebp-wireframe {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 780px) {
  .base-image {
    .ebp-mobile {
      margin: 4em 0;
      width: 100%;
    }

    .ebp-wireframe {
      margin: 0;
      width: 90%;
      padding-top: 4rem;
    }

    .ebp-webdesign {
      width: 80%;
      padding: 4rem 0;
    }
  }
}

.half {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 3rem 0 0;
  position: relative;
  z-index: 300;

  div {
    width: 100%;
  }
}

@media screen and (min-width: 980px) {
  .half {
    flex-direction: row;
    align-items: center;
  }
}

// ================
// Marketing
// ================

.marketing {
  // padding: 4.8rem 0;
}

.banner-section {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;

  iframe {
    border: 0;
    text-align: center;
    display: block;
    margin: 1rem auto;
    width: 300px;
    height: 250px;
  }
}

@media screen and (min-width: 1220px) {
  .banner-section {
    flex-direction: row;
    justify-content: space-between;

    iframe {
      margin: 0 auto;
    }
  }
}