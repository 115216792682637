@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

html,
body {
  font-size: 10px;
  margin: 0;
  padding: 0;
  // font-family: Arial, sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

.section {
  padding: 4.8rem 0;
}

h1 {
  font-size: 4em;
  // letter-spacing: 1px;
}

p {
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0.2px;
}

a {
  color: #5959f5;
}