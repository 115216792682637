.carousel {

  // max-height: 60rem;
  // height: 50%;
  .control-dots {
    position: relative;
  }
}

.carousel .slide {
  background: transparent;
}

.carousel .control-dots .dot {
  background: #333;
}


.mrec {
  // transform: scale(0.4);
  margin: 0 10px;
}

// @media screen and (max-width: 970px) {
// }