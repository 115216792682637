.not-found {
  background: dadada;
  // color: #fff;
  // padding: 8rem 0;
  height: calc(100vh - 7rem);
  display: flex;
  align-items: center;
  text-align: center;

  a {
    color: #000;
  }
}